@import "../../scss/_variables.scss";

$sidebar-width: 25rem;

.design-section-stage {
    padding-right: $sidebar-width + 1;
}

.design-stage {
    &.is-absolute .design-section-sidebar {
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
    }

    &.is-fixed {
        .design-section-sidebar {
            position: fixed;
            right: 1.5rem;
            bottom: 1rem;
            top: $nav-link-height + 1;
        }

        #form-page-header, footer {
            padding-right: $sidebar-width + 2.5rem;
        }
    }
}

.design-section-sidebar {
    min-width: $sidebar-width;
    z-index: $zindex-fixed;
    width: $sidebar-width;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: $gray-200;
    border-radius: $border-radius;
    box-shadow: $card-box-shadow;
    resize: horizontal;
    overflow-y: auto;
    overflow-x: hidden;
    direction: rtl;

    .nav-pills, .sidebar-header, .sidebar-content{
        direction: ltr;
    }
}